import { Avatar, Button, Icon, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { logout } from "app/redux/actions/AuthActions";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ecryptString } from "utils";
import { sendEmailVerification } from "../services/firebase/firebaseUserService";
import AdwiserMenu from "./AdwiserMenu";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  topbar: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 999,
    "& .topbar-content": {
      paddingLeft: 32,
      paddingRight: 32,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
  },
}));

const Topbar = () => {
  const [encryptedId, setEncryptedId] = useState("");

  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state);
  const { enqueueSnackbar: snackbar } = useSnackbar();

  const handleLogout = () => {
    dispatch(logout());
  };

  const verifyEmail = async () => {
    try {
      await sendEmailVerification();
      snackbar("Verification email was sent", { variant: "success" });
    } catch (error) {
      snackbar("Couldn't send verification email", { variant: "error" });
    }
  };

  useEffect(() => {
    setEncryptedId(ecryptString(user?.id || ""));
  }, [user]);

  // 7202090102083

  return (
    <div className={clsx("h-64 bg-primary text-white", classes.topbar)}>
      <div
        className={clsx({
          "flex justify-between items-center topbar-content h-full": true,
          container: pathname === "/",
        })}
      >
        <Link to="/">
          <img
            className="h-32"
            src="/assets/images/adwiser/adwiser_logo.png"
            alt=""
          />
        </Link>
        <div className="flex items-center">
          {user.email ? (
            <Fragment>
              <AdwiserMenu
                menuButton={
                  <div className="flex items-center cursor-pointer py-2">
                    <Avatar className="w-32 h-32" src={user.profilePhotoUrl} />
                    <b className="uppercase ml-2">
                      {user?.name || user?.email?.split("@")[0]}
                    </b>
                  </div>
                }
              >
                {["adviser", "demoadviser"].includes(user.type) && (
                  <Fragment>
                    <Link to="/dashboard">
                      <MenuItem>
                        <Icon fontSize="small">dashboard</Icon>
                        <span className="ml-4">Dashboard</span>
                      </MenuItem>
                    </Link>
                    <Link to="/dashboard/ratings-reviews">
                      <MenuItem>
                        <Icon fontSize="small">trending_up</Icon>
                        <span className="ml-4">Rating Review</span>
                      </MenuItem>
                    </Link>
                    <Link to="/edit-profile">
                      <MenuItem>
                        <Icon fontSize="small">how_to_reg</Icon>
                        <span className="ml-4">Edit Profile</span>
                      </MenuItem>
                    </Link>
                    <Link to={`/profile/${encryptedId}`}>
                      <MenuItem>
                        <Icon fontSize="small">remove_red_eye</Icon>
                        <span className="ml-4">View Profile</span>
                      </MenuItem>
                    </Link>
                    <Link to="/pricing">
                      <MenuItem>
                        <Icon fontSize="small">model_training</Icon>
                        <span className="ml-4">Manage Plan</span>
                      </MenuItem>
                    </Link>
                  </Fragment>
                )}

                {!user?.emailVerified && (
                  <MenuItem onClick={verifyEmail}>
                    <Icon fontSize="small">verified</Icon>
                    <span className="ml-4">Verify Email</span>
                  </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                  <Icon fontSize="small">power_settings_new</Icon>
                  <span className="ml-4">Logout</span>
                </MenuItem>
              </AdwiserMenu>
            </Fragment>
          ) : (
            <Link to="/session">
              <Button className="px-4 bg-secondary">LOGIN | REGISTER</Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
