import { firebaseApp } from "./firebase";

const db = firebaseApp.firestore();
const storage = firebaseApp.storage();

export const getUserById = (id) => {
  return db
    .collection("users")
    .doc(id)
    .get()
    .then((snap) => snap.data());
};

export const getAdviserByEmail = (email) => {
  return db
    .collection("users")
    .where("email", "==", email)
    .get()
    .then((snaps) => snaps.docs.map((doc) => doc.data()));
};

export const updateFirebaseUserData = async (
  id,
  { profilePhoto, companyPhoto, ...data }
) => {
  try {
    if (profilePhoto) {
      let storageRef = storage
        .ref("images/profile")
        .child(data.uid)
        .child("profile");

      data.profilePhotoUrl = await storageRef
        .put(profilePhoto)
        .then(async () => await storageRef.getDownloadURL().then((url) => url));
    }

    if (companyPhoto) {
      let storageRef = storage
        .ref("images/company")
        .child(data.email)
        .child("company");

      data.companyPhotoUrl = await storageRef
        .put(companyPhoto)
        .then(async () => await storageRef.getDownloadURL().then((url) => url));
    }
  } catch (err) {
    console.log(err);
  }

  data.lastUpdated = new Date().toISOString();

  return await db
    .collection("users")
    .doc(id)
    .set({ ...data }, { merge: true })
    .then((data) => {
      console.log("data sent succesfull");
    })
    .catch((err) => {
      console.log(err);
    });
};

export const resetPassword = (email) => {
  return firebaseApp.auth().sendPasswordResetEmail(email);
};

export const sendEmailVerification = () => {
  return firebaseApp.auth().currentUser.sendEmailVerification();
};
