import { Button, Card, Dialog } from "@material-ui/core";
import React, { useState } from "react";

const UserAgreement = () => {
  const [open, setOpen] = useState(localStorage.getItem("agreement-accepted"));

  const handleAccept = () => {
    localStorage.setItem("agreement-accepted", "true");
    setOpen(true);
  };

  return (
    <Dialog open={!open} maxWidth="sm" scroll="body">
      <Card className="px-6 py-4 max-w-400">
        <div className="pb-4 flex items-center text-primary text-24">
          <img
            className="h-64 w-64"
            src="/assets/images/adwiser/sq_logo.png"
            alt="logo"
          />
          <h2 className="m-0 text-primary">AdWiser</h2>
        </div>
        <h4 className="text-primary mt-0">Welcome to Adwiser</h4>
        <p>
          By visiting our website or using any of our services, you agree with
          the{" "}
          <a
            className="text-dark-secondary"
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/adwiser-za.appspot.com/o/privacy%20policy%2FAdwiser%20terms%20of%20use%2020200707.pdf?alt=media&token=e4220fb1-d840-4b2e-bc40-978b1f9116ea"
            rel="noreferrer noopener"
          >
            Terms of Services
          </a>
        </p>
        <p>
          By visiting our website, using any of our services or otherwise giving
          us your personal information (including giving someone else the
          authority to give your personal information to us), you agree that we
          may handle your personal information according to our{" "}
          <a
            className="text-dark-secondary"
            target="_blank"
            href="https://firebasestorage.googleapis.com/v0/b/adwiser-za.appspot.com/o/privacy%20policy%2FAdWiser%20Privacy%20Policy%2020200707.pdf?alt=media&token=af61c02e-e9c1-45f0-82ee-3ff170f201c3"
            rel="noreferrer noopener"
          >
            privacy statement
          </a>
        </p>
        <div className="text-center pt-4">
          <Button
            className="px-6"
            color="primary"
            variant="contained"
            onClick={handleAccept}
          >
            I accept
          </Button>
        </div>
      </Card>
    </Dialog>
  );
};

export default UserAgreement;
