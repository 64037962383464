import {
  AUTH_ERROR,
  AUTH_LOADING,
  AUTH_SUCCESS,
  RESET_PASSWORD,
} from "../actions/AuthActions";

const initialState = {
  success: false,
  loading: true,
  error: {
    username: null,
    password: null,
  },
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOADING: {
      return {
        ...state,
        loading: action.data,
      };
    }
    case AUTH_SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
      };
    }
    case RESET_PASSWORD: {
      return {
        ...state,
        success: true,
        loading: false,
      };
    }
    case AUTH_ERROR: {
      return {
        success: false,
        loading: false,
        error: action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default AuthReducer;
