import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import ProfileReducer from "./ProfileReducer";
import searchReducer from "./SearchReducer";
import UserReducer from "./UserReducer";

const RootReducer = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  profile: ProfileReducer,
  search: searchReducer,
});

export default RootReducer;
