/* eslint-disable react-hooks/exhaustive-deps */
import Loader from "app/components/Loader";
import { checkFirebaseAuth } from "app/redux/actions/AuthActions";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(checkFirebaseAuth());
  }, [checkFirebaseAuth]);

  return loading ? <Loader /> : <Fragment>{children}</Fragment>;
};

export default Auth;
