import { firebaseAnalytics } from "app/services/firebase/firebase";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";

const AuthGuard = ({ component: Component, ...rest }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const { user } = useSelector((state) => state);
  const { authRole, isPrivate = true } = rest;

  const checkRouteAccess = () => {
    return authRole
      ? authRole.includes(user.type)
      : isPrivate
      ? !!user.id
      : true;
  };

  useEffect(() => {
    firebaseAnalytics.setCurrentScreen(pathname);
  }, [pathname]);

  useEffect(() => {
    if (user?.fsp && !user?.emailVerified) {
      if (pathname !== "/")
        snackbar("Please, verify your email", { variant: "error" });
      history.push("/");
    }
  }, [history, pathname, snackbar, user]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return checkRouteAccess() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/session/signin",
              state: { redirectUrl: pathname },
            }}
          />
        );
      }}
    />
  );
};

export default AuthGuard;
