import React, { lazy } from "react";
import { Redirect } from "react-router-dom";

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

export const routes = [
  {
    path: "/",
    component: lazy(() => import("./views/home/Home")),
    exact: true,
    isPrivate: false,
  },
  {
    path: "/checklist",
    component: lazy(() => import("./views/checklist/CheckList")),
    exact: true,
    isPrivate: false,
  },
  {
    path: "/dashboard/ratings-reviews",
    component: lazy(() => import("./views/dashboard/DashboardRatingReviews")),
    authRole: ["adviser", "demoadviser"],
  },
  {
    path: "/dashboard",
    component: lazy(() => import("./views/dashboard/Dashboard")),
    authRole: ["adviser", "demoadviser"],
  },
  {
    path: "/profile/:id",
    component: lazy(() => import("./views/profile/Profile")),
    isPrivate: false,
  },
  {
    path: "/edit-profile",
    component: lazy(() => import("./views/profile/ProfileEditor")),
    authRole: ["adviser", "demoadviser"],
  },
  {
    path: "/review/:id",
    component: lazy(() => import("./views/review/ReviewCreator")),
  },
  ...errorRoute,
];

export const topbarLessRoutes = [
  {
    path: "/pricing",
    // exact: true,
    component: lazy(() => import("./views/pricing/Pricing")),
  },
  {
    path: "/session",
    // exact: true,
    component: lazy(() => import("./views/sessions/Session")),
  },
];
