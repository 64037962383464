import algoliasearch from "algoliasearch";

const searchClient = algoliasearch(
  "TIJKTDUCX6",
  "8125e71ad3b1b37c629729e2f5c78107"
);

const nameIndex = searchClient.initIndex("adviser");
const registeredIndex = searchClient.initIndex("registered-adviser");

export const SET_SEARCH_PAGE = "SET_SEARCH_PAGE";
export const SET_SEARCH_RESULTS = "SET_SEARCH_RESULTS";
export const SET_SEARCH_ATTRIBUTES = "SET_SEARCH_ATTRIBUTES";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";

export const searchAlgolia = (page = 0) => {
  return async (dispatch, getState) => {
    let state = getState();

    let {
      search: { attributes },
    } = state;

    let { query, radius, geocode, language = [], services = [] } = attributes;
    let index = query ? nameIndex : registeredIndex;

    // other search will be done in algolia
    let languageFilter = [];
    let skillFilter = [];

    language.forEach((item) => {
      languageFilter.push(`language:${item}`);
      languageFilter.push(`otherLanguage:${item}`);
    });
    services.forEach((item) => {
      skillFilter.push(`skills:${item}`);
    });

    return index
      .search(query || "*", {
        facetFilters: [languageFilter, skillFilter],
        page,
        removeWordsIfNoResults: "allOptional",
        aroundRadius: radius ? radius : "all",
        aroundLatLngViaIP: radius === "all" ? false : !!geocode,
        // aroundLatLngViaIP: radius === "all" ? false : !!geocode,
        aroundLatLng: radius === "all" ? "" : geocode,
      })
      .then(({ hits: searchResults, page, nbHits: totalResult, ...rest }) => {
        dispatch({
          type: SET_SEARCH_RESULTS,
          data: {
            searchResults,
            page,
            totalResult,
          },
        });
      });
  };
};

export const setSearchAttributes = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SEARCH_ATTRIBUTES,
      data: data,
    });
  };
};

export const clearSearchResults = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SEARCH_RESULTS,
    });
  };
};
