import {
  FacebookProvider,
  firebaseAnalytics,
  firebaseApp,
  GoogleProvider,
} from "app/services/firebase/firebase";
import { redirectTo } from "utils";
import { updateFirebaseUserData } from "../../services/firebase/firebaseUserService";
import { removeUserData, setUserData } from "./UserActions";

export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_LOADING = "AUTH_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";

const auth = firebaseApp.auth();
const db = firebaseApp.firestore();

export const setAuthLoadingStatus = (status = false) => {
  return (dispatch) =>
    dispatch({
      type: AUTH_LOADING,
      data: status,
    });
};

export const checkFirebaseAuth = () => {
  return (dispatch) => {
    return auth.onAuthStateChanged((user) => {
      if (user) {
        let defaultUserData = {
          id: user.uid,
          email: user.email,
          name: user.displayName,
          profilePhotoUrl: user.photoURL,
          emailVerified: user.emailVerified,
        };

        dispatch(setUserData({ ...defaultUserData }));

        if (user?.email)
          db.collection("users")
            .where("email", "==", user.email)
            .onSnapshot((snaps) => {
              let [userData = {}] = snaps.docs.map((doc) => doc.data());

              dispatch(setUserData({ ...defaultUserData, ...userData }));

              dispatch({
                type: AUTH_SUCCESS,
              });

              if (userData.fsp) {
                firebaseAnalytics.logEvent("adviser_signin", {
                  email: user.email,
                });
              } else {
                firebaseAnalytics.logEvent("client_signin", {
                  email: user.email,
                });
              }
              firebaseAnalytics.setUserId(user.uid);
            });
      } else
        dispatch({
          type: AUTH_LOADING,
          data: false,
        });
    });
  };
};

export const loginWithGoogleProvider = () => {
  return (dispatch) => {
    return auth.signInWithPopup(GoogleProvider).then((user) => {
      if (user) {
        dispatch({
          type: AUTH_SUCCESS,
        });
      }
    });
  };
};

export const loginWithFacebookProvider = () => {
  return (dispatch) => {
    return auth.signInWithPopup(FacebookProvider).then((user) => {
      if (user) {
        dispatch({
          type: AUTH_SUCCESS,
        });
      }
    });
  };
};

export const loginWithEmailAndPassword = ({ email, password, remember }) => {
  return async (dispatch) => {
    if (!remember)
      auth.setPersistence(firebaseApp.auth.Auth.Persistence.SESSION);
    return auth.signInWithEmailAndPassword(email, password);
  };
};

export const signupWithEmailAndPassword = (
  { email, password, ...rest },
  type = "client"
) => {
  return async (dispatch) => {
    let res = "Account created successfully";
    let docId = rest.id;

    let { user } = await auth.createUserWithEmailAndPassword(email, password);

    if (type === "adviser") {
      user.sendEmailVerification();
      res = "Verify your email";

      firebaseAnalytics.logEvent("adviser_signup", {
        email,
        uid: user.uid,
        plan: "free",
      });
    } else {
      docId = user.uid;

      firebaseAnalytics.logEvent("client_signup", {
        email,
        uid: user.uid,
      });
    }

    // console.log(user);
    // console.log(res);

    await updateFirebaseUserData(docId, {
      uid: user.uid,
      email: email.toLowerCase(),
      type,
      ...rest,
    });

    return res;
  };
};

export const resetPassword = ({ email }) => {
  return (dispatch) => {
    dispatch({
      payload: email,
      type: RESET_PASSWORD,
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    return auth.signOut().then(() => {
      dispatch(removeUserData());
      redirectTo("/");
      console.log("Logged out");
    });
  };
};
