const firebaseConfig = {
  apiKey: "AIzaSyC0xXUOPsnUDWiXNB2bD6BKb9aJl0uQNbM",
  authDomain: "adwiser-za.firebaseapp.com",
  databaseURL: "https://adwiser-za.firebaseio.com",
  projectId: "adwiser-za",
  storageBucket: "adwiser-za.appspot.com",
  messagingSenderId: "57196549299",
  appId: "1:57196549299:web:f1ae408d43b9c2a2544cc6",
  measurementId: "G-2SX4RR1JZE",
};

export default firebaseConfig;
