import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/performance";
import "firebase/storage";
import firebaseConfig from "./firebaseConfig";

firebase.initializeApp(firebaseConfig);

export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
export const FacebookProvider = new firebase.auth.FacebookAuthProvider();

export const firebaseApp = firebase;
export const firebaseAnalytics = firebase.analytics();
export const firebasePerformance = firebase.performance();
