import {
  CLEAR_SEARCH_RESULTS,
  SET_SEARCH_ATTRIBUTES,
  SET_SEARCH_PAGE,
  SET_SEARCH_RESULTS,
} from "../actions/SearchActions";

const initialState = {
  page: 0,
  searched: false,
  searchResults: [],
  attributes: {
    radius: "all",
  },
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_RESULTS: {
      return {
        ...state,
        ...action.data,
        searched: true,
      };
    }
    case SET_SEARCH_ATTRIBUTES: {
      return {
        ...state,
        attributes: { ...state.attributes, ...action.data },
      };
    }
    case CLEAR_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: [],
        searched: false,
      };
    }
    case SET_SEARCH_PAGE: {
      return {
        ...state,
        page: action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default searchReducer;
