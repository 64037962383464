import AuthGuard from "app/auth/AuthGuard";
import Topbar from "app/components/Topbar";
import { routes } from "app/Routes";
import React, { Suspense } from "react";
import { Switch } from "react-router-dom";
import Loader from "../components/Loader";

const AppLayout = () => {
  return (
    <div className="relative">
      <Topbar />
      <div className="mt-16">
        <Suspense fallback={<Loader />}>
          <Switch>
            {routes.map((item, ind) => (
              <AuthGuard key={ind} {...item} />
            ))}
          </Switch>
        </Suspense>
      </div>
    </div>
  );
};

export default AppLayout;
