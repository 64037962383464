import { getUserById } from "../../services/firebase/firebaseUserService";

export const SET_USER_PROFILE_DATA = "SET_USER_PROFILE_DATA";

export const getUserProfileDataById = (id) => {
  return (dispatch) => {
    return getUserById(id).then((data) => {
      dispatch({
        type: SET_USER_PROFILE_DATA,
        data: data || {},
      });
    });
  };
};
