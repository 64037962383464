/* eslint-disable react-hooks/exhaustive-deps */
import { ThemeProvider } from "@material-ui/core/styles";
import history from "history.js";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import GlobalStyles from "styles/GlobalStyles";
import Auth from "./auth/Auth";
import Loader from "./components/Loader";
import Snackbar from "./components/Snackbar";
import AppLayout from "./layouts/AppLayout";
import { materialTheme } from "./materialTheme";
import { Store } from "./redux/Store";
import { topbarLessRoutes } from "./Routes";
import UserAgreement from "./views/privacy-policy/UserAgreement";

const App = () => {
  return (
    <Provider store={Store}>
      <ThemeProvider theme={materialTheme}>
        <GlobalStyles>
          <Snackbar>
            <Router history={history}>
              <Auth>
                <Suspense fallback={<Loader />}>
                  <Switch>
                    {topbarLessRoutes.map((item, ind) => (
                      <Route
                        {...item}
                        key={ind}
                        component={() => <item.component />}
                      />
                    ))}
                    <Route path="/" component={AppLayout} />
                  </Switch>
                  <UserAgreement />
                </Suspense>
              </Auth>
            </Router>
          </Snackbar>
        </GlobalStyles>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
