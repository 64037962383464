import { SET_USER_PROFILE_DATA } from "../actions/ProfileActions";

const ProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_USER_PROFILE_DATA: {
      return {
        ...state,
        adviserProfile: action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default ProfileReducer;
