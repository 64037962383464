export const SET_USER_DATA = "USER_SET_DATA";
export const REMOVE_USER_DATA = "USER_REMOVE_DATA";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export const setUserData = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user,
    });
  };
};

export const removeUserData = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_USER_DATA,
    });
  };
};
